.wrapper {
    background-color: rgb(38, 9, 224);
    background-repeat: no-repeat;
    display: flex;    
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.wrappe {
    font-size: 40px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 300px;
}

.book {
    background-repeat: no-repeat;
}

.button {
    font-size: 20px;
    position: absolute;
    top: 50px;
    left: 50px;
    color: rgb(254, 7, 7);
    background-color: transparent;
    border: 30;
    z-index: 100;
}

.firstPage {
    background-color: blueviolet;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.secondPage {
    background-color: aqua;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.thirdPage {
    background-color: blue;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center; 
}

@media screen and (max-width: 576px) {
    .firstPage,
    .secondPage,
    .thirdPage {
        left: 15px !important;
        right: 15px !important;
        min-width: calc(100% - 30px) !important;
        width: calc(100% - 30px) !important;
    }

    .book {
        min-width: 100% !important;
    }
}