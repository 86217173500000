.wrapper {
    background-image: url('../../../assets/images/0000.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
}

.button {
    font-size: 20px;
    position: absolute;
    top: 50px;
    left: 50px;
    color: rgb(254, 7, 7);
    background-color: transparent;
    border: 30;
    z-index: 100;
}

@media (max-width: 700px) {
    .wrapper {
        background-image: url('../../../assets/images/slaid.png');
        background-size: contain;
        font-size: 20px;
        height: 100vh;
    }
}