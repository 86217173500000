.wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  width: 100%;
  object-fit: cover;
  padding: 20px 0;
}

.wrapper img {
  width: 200px;
  height: 200px;
  object-fit: cover;
}

@media screen and (max-width: 419px) {
  .wrapper img {
    width: 150px;
    height: 150px;
  }
}