.wrapper {
    font-size: 40px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
}

.button {
    font-size: 20px;
    position: absolute;
    top: 50px;
    left: 50px;
    color: rgb(254, 7, 7);
    background-color: transparent;
    border: 30;
    z-index: 100;
}

.wrapper a {
    position: relative;
    z-index: 100;
}

@media screen and (max-width: 568px) {
    .wrapper {
        font-size: 30px;
    }
}