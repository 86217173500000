.expanded {
  transform: scale(1.5);
  position: absolute;
  width: 100% !important;
  height: 100% !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

@media screen and (max-width: 576px) {
  .expanded {
    object-fit: contain !important;
  }
}